<template>
  <div
    id="chartdiv"
    style="width: 100%; height: 400px;"
  />
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import am4themes_animated from '@amcharts/amcharts4/themes/animated'

export default {
  name: 'SimpleColumnChart',
  props: ['chartData'],
  data() {
    return {
      chart: null,
    }
  },
  watch: {
    chartData: {
      handler(newData) {
        this.chart.data = newData
      },
      deep: true,
    },
  },
  mounted() {
    am4core.useTheme(am4themes_animated)

    const chart = am4core.create('chartdiv', am4charts.XYChart)
    chart.data = this.chartData

    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis())
    categoryAxis.dataFields.category = 'commercial'
    categoryAxis.renderer.grid.template.location = 0
    categoryAxis.renderer.minGridDistance = 30

    const valueAxis = chart.yAxes.push(new am4charts.ValueAxis())

    const series = chart.series.push(new am4charts.ColumnSeries())
    series.dataFields.valueY = 'total_realisation'
    series.dataFields.categoryX = 'commercial'
    series.name = 'Réalisation'
    series.columns.template.tooltipText = '{categoryX}: [bold]{valueY}[/]'
    series.columns.template.fillOpacity = 0.8

    const columnTemplate = series.columns.template
    columnTemplate.strokeWidth = 2
    columnTemplate.strokeOpacity = 1

    this.chart = chart
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose()
    }
  },
}
</script>

<style scoped>
/* CSS styles go here */
</style>
